export enum TRANSACTION_TYPE {
  CRYPTO_DEPOSIT = "crypto-deposit",
  GIFT_CARD_BUY = "gift-card-buy",
  UTILITY_BILL_PAYMENT = "utility-bill-payment",
  FIAT_WITHDRAW = "fiat-withdrawal",
  FIAT_DEPOSIT = "fiat-deposit",
  CRYPTO_WITHDRAW = "crypto-withdrawal",
  CRYPTO_BUY = "crypto-buy",
  CRYPTO_SELL = "crypto-sell",
  TRANSFER = "transfer",
  AIRTIME_PURCHASE = "airtime-purchase",
  AIRTIME_TOP_UP = "airtime-top-up",
  BILL_PURCHASE = "bill-purchase",
  DATA_BUNDLE = "data-bundle",
  GIFT_CARD_SELL = "gift-card-sell",
  CABLE_TV = "cable-tv",
  DATA_BUNDLE_PURCHASE = "data-bundle",
  SWAP = "swap",
  REVERSAL = "reversal",
  BET_TOP_UP = "bet-top-up",
  CREATE_CARD = "create-card",
  FUND_CARD = "fund-card",
  SWAP_ORDER = "swap-order",
}

export enum TRANSACTION_STATUS_TYPE {
  COMPLETED = "completed",
  UNCONFIRMED = "unconfirmed",
  PAID = "paid",
  FAILED = "failed",
  PENDING = "pending",
  PROCESSING = "processing",
  REJECTED = "rejected",
  ACTIVE = "active",
  DECLINED = "declined",
  CONFIRMED = "confirmed",
  SUCCESSFUL = "successful",
  CANCELLED = "cancelled",
  CANCELED = "canceled",
}
